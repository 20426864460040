import { eventWithTime, record } from 'rrweb'
import rrwebPlayer from 'rrweb-player'
import { pack, unpack } from '@rrweb/packer'
import { RrwebEventExporter } from './exporter'
import { SessionDebuggerConfigs } from '../types'
import { getRecordConsolePlugin } from '@rrweb/rrweb-plugin-console-record'
import { IndexedDBService } from './indexedDbService'

export class RecorderBrowserSDK {
  private stopFn?: () => void
  private config?: SessionDebuggerConfigs
  private exporter: RrwebEventExporter | undefined
  private indexedDBService: IndexedDBService

  constructor() {
    this.indexedDBService = new IndexedDBService()
  }

  init(config: SessionDebuggerConfigs): void {
    this.config = config
    this.exporter = new RrwebEventExporter(config.exporterApiBaseUrl || '', config.apiKey)
  }

  start(sessionId): void {
    if (!this.config) {
      throw new Error('Configuration not initialized. Call init() before start().')
    }
    this.stopFn = record({
      packFn: pack,
      recordCanvas: true,
      maskAllInputs: true,
      plugins: [getRecordConsolePlugin({ level: ['info', 'log', 'warn', 'error'] })],
      emit: async (event) => {
        if (this.exporter) {
          const timestamp = Date.now()
          this.exporter.send({ event, debugSessionId: sessionId, timestamp })
          await this.indexedDBService.saveEvent(event)
        }
      },
    })
  }

  stop(): void {
    if (this.stopFn) {
      this.stopFn()
    }
  }

  async preview(): Promise<void> {
    const storedEvents = await this.indexedDBService.getAllEvents()
    if (storedEvents.length>1) {
      const replayContainer = document.getElementById('mp-preview-debugger-recording')
      new rrwebPlayer({
        target: replayContainer!,
        props: {
          events: storedEvents.map(unpack),
          autoPlay: false,
          skipInactive: true,
          showController: true,
          width: replayContainer?.offsetWidth,
          height: replayContainer?.offsetHeight,
        },
      }).getReplayer()
    }
  }

  async clearStoredEvents(): Promise<void> {
    await this.indexedDBService.clearEvents()
    const replayContainer = document.getElementById('mp-preview-debugger-recording')
    if (replayContainer) {
      replayContainer.innerHTML = ''
    }
  }
}
